@import url(https://fonts.googleapis.com/css?family=Lato:300,400,600,700,900&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,600,700,900&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,600,700,900&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,600,700,900&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,600,700,900&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,600,700,900&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,600,700,900&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,600,700,900&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,600,700,900&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,600,700,900&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,600,700,900&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600);
@import url(//maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,600,700,900&subset=latin-ext);
.article-view--container {
  background: transparent; }
  .article-view--container .article-view--content {
    position: absolute;
    top: 0;
    right: 0;
    background: #cceceb url(/static/media/sfj_teppe.a02c2eb1.jpg) no-repeat;
    background-size: cover;
    background-color: #89d0c8;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 45vw;
    overflow: auto;
    margin-bottom: 10px;
    padding: 10px; }
    @media (max-width: 768px) {
      .article-view--container .article-view--content {
        box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.2);
        top: 60vh;
        left: 10px;
        right: 10px;
        width: auto;
        margin-bottom: 0;
        min-height: 60vh; } }
    .article-view--container .article-view--content .articles--container {
      background: transparent; }
      .article-view--container .article-view--content .articles--container .articleList--thumbnail img {
        display: none; }
      .article-view--container .article-view--content .articles--container .articleList--thumbnail.articleList--withThumbnail {
        min-height: 150px; }
        .article-view--container .article-view--content .articles--container .articleList--thumbnail.articleList--withThumbnail .caption {
          background: rgba(0, 58, 105, 0.75);
          max-height: 100%;
          max-height: calc(100% - 10px);
          overflow: auto; }
          .article-view--container .article-view--content .articles--container .articleList--thumbnail.articleList--withThumbnail .caption .pull-right.btn.btn-link {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            overflow: hidden;
            width: 100%;
            display: block;
            text-indent: -9999em;
            text-transform: uppercase; }
        .article-view--container .article-view--content .articles--container .articleList--thumbnail.articleList--withThumbnail img {
          display: inherit; }
      .article-view--container .article-view--content .articles--container .articleList--entry {
        border: 1px solid #addfd9;
        background: #fff;
        border-radius: 4px;
        border-top-width: 6px !important; }
      .article-view--container .article-view--content .articles--container .article:hover {
        border-color: #00a29b; }
      .article-view--container .article-view--content .articles--container .articles--selector #articleSourceSelector {
        background: #40b9b4;
        color: #000000;
        border-color: #40b9b4; }

.articleList--thumbnail img {
  display: none; }

.articleList--thumbnail.articleList--withThumbnail {
  min-height: 150px; }
  .articleList--thumbnail.articleList--withThumbnail .caption {
    background: rgba(0, 58, 105, 0.75);
    max-height: 100%;
    max-height: calc(100% - 10px);
    overflow: auto; }
    .articleList--thumbnail.articleList--withThumbnail .caption .pull-right.btn.btn-link {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: hidden;
      width: 100%;
      display: block;
      text-indent: -9999em;
      text-transform: uppercase; }
  .articleList--thumbnail.articleList--withThumbnail img {
    display: inherit; }

.articleList--entry {
  border: 1px solid #99dad7;
  background: #fff;
  border-radius: 4px;
  border-top-width: 6px !important;
  cursor: pointer; }
  .articleList--entry .read-more-btn {
    float: right; }

.article:hover {
  border-color: #00a29b; }

.carousel button.slick-arrow {
  width: 44px;
  top: 50%;
  margin-top: -10px;
  z-index: 1; }
  .carousel button.slick-arrow::before {
    font-size: 44px; }
  .carousel button.slick-arrow.slick-prev {
    left: 0px; }
  .carousel button.slick-arrow.slick-next {
    right: 0px; }

.carousel div.slider-image--container {
  position: relative;
  height: 900px; }
  .carousel div.slider-image--container img {
    width: 100%;
    height: auto;
    z-index: 1000; }
  .carousel div.slider-image--container .slider-image--caption {
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    bottom: 0;
    max-height: 40%;
    color: #ffffff;
    padding: 10px; }

.gallery--carousel button.slick-arrow {
  width: 44px;
  top: 50%;
  margin-top: -10px;
  z-index: 1; }
  .gallery--carousel button.slick-arrow::before {
    font-size: 44px; }
  .gallery--carousel button.slick-arrow.slick-prev {
    left: -150px; }
    @media (max-width: 1024px) {
      .gallery--carousel button.slick-arrow.slick-prev {
        left: 0; } }
    .gallery--carousel button.slick-arrow.slick-prev:before {
      color: rgba(0, 0, 0, 0.5); }
      .gallery--carousel button.slick-arrow.slick-prev:before:hover {
        color: rgba(0, 0, 0, 0.6); }
  .gallery--carousel button.slick-arrow.slick-next {
    right: -150px; }
    @media (max-width: 1024px) {
      .gallery--carousel button.slick-arrow.slick-next {
        right: 0; } }
    .gallery--carousel button.slick-arrow.slick-next:before {
      color: rgba(0, 0, 0, 0.5); }
      .gallery--carousel button.slick-arrow.slick-next:before:hover {
        color: rgba(0, 0, 0, 0.6); }

.gallery--carousel div.slick-slide {
  float: none;
  display: inline-block;
  vertical-align: middle; }
  .gallery--carousel div.slick-slide div.slider-image--container {
    position: relative;
    max-height: 800px; }
    .gallery--carousel div.slick-slide div.slider-image--container img {
      width: 100%;
      max-height: 100%;
      height: auto;
      z-index: 1000;
      object-fit: cover;
      opacity: 100; }
    .gallery--carousel div.slick-slide div.slider-image--container .slider-image--caption {
      width: 100%;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      bottom: 0;
      max-height: 40%;
      color: #ffffff;
      padding: 10px;
      z-index: 1001; }

.icon {
  position: relative;
  display: block; }
  .icon svg {
    padding: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }

body.modal-open {
  overflow: hidden; }

.modal--overlay {
  position: fixed;
  background: rgba(255, 255, 255, 0.6);
  display: block;
  height: 100vh;
  width: 100vw;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  overflow: auto; }
  .modal--overlay .modal--content {
    position: relative;
    top: 50%;
    left: 50%;
    width: 90%;
    max-width: 690px;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    border-radius: 10px;
    z-index: 1000;
    height: 80vh; }
    @media (max-width: 768px) {
      .modal--overlay .modal--content {
        top: 75px;
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
        width: 90%;
        z-index: 1000;
        margin-bottom: 30px; } }

.skulenmin--article {
  background: #ffffff;
  padding: 10px; }
  .skulenmin--article .skulenmin--article-content-header {
    padding-top: 10px; }
  .skulenmin--article .skulenmin--article-content {
    margin: 20px 0;
    overflow-x: auto; }
    .skulenmin--article .skulenmin--article-content .thumb {
      margin-bottom: 15px; }
    .skulenmin--article .skulenmin--article-content br {
      display: none; }
    .skulenmin--article .skulenmin--article-content h2 {
      font-size: 20px; }
    .skulenmin--article .skulenmin--article-content h3 {
      font-size: 16px; }
    .skulenmin--article .skulenmin--article-content table.wikitable {
      border: 1px solid #333;
      width: 100%;
      text-align: center;
      border-collapse: collapse; }
    .skulenmin--article .skulenmin--article-content table.wikitable td,
    .skulenmin--article .skulenmin--article-content table.wikitable th {
      border: 1px solid #333;
      padding: 3px; }
    .skulenmin--article .skulenmin--article-content table.wikitable tbody td {
      font-size: 12px; }
    .skulenmin--article .skulenmin--article-content table.wikitable tr:nth-child(even) {
      background: #d0e4f5; }
    .skulenmin--article .skulenmin--article-content table.wikitable {
      border-bottom: 1px solid #333333; }
    .skulenmin--article .skulenmin--article-content table.wikitable th {
      font-size: 13px;
      font-weight: bold;
      color: #333333;
      text-align: center;
      border-left: 1px solid #333333; }
    .skulenmin--article .skulenmin--article-content table.wikitable th:first-child {
      border-left: none; }
    .skulenmin--article .skulenmin--article-content table.wikitable td {
      font-size: 12px; }
    @media (min-width: 768px) and (max-width: 1024px) {
      .skulenmin--article .skulenmin--article-content table.wikitable {
        overflow-x: auto;
        display: block; } }
    @media (max-width: 580px) {
      .skulenmin--article .skulenmin--article-content table.wikitable {
        overflow-x: auto;
        display: block; } }
    .skulenmin--article .skulenmin--article-content .additionalColumn .additionalColumnLabel {
      font-style: italic;
      font-weight: 400; }
  .skulenmin--article .skulenmin--article-source span {
    font-style: italic;
    margin-right: 6px; }
  .skulenmin--article .skulenmin--article--button-bar {
    display: -webkit-flex;
    display: flex;
    margin-top: 30px; }
    .skulenmin--article .skulenmin--article--button-bar a.link-button {
      -webkit-flex: 1 1;
              flex: 1 1;
      padding: 10px 8px;
      background: #cceceb;
      text-decoration: none;
      margin: 5px 2px 5px 2px;
      text-align: center;
      color: #000000;
      position: relative; }
      .skulenmin--article .skulenmin--article--button-bar a.link-button span.icon {
        display: block; }
        .skulenmin--article .skulenmin--article--button-bar a.link-button span.icon svg {
          top: auto;
          left: auto;
          -webkit-transform: none;
                  transform: none;
          position: relative;
          fill: #000000;
          width: 34px;
          height: 34px; }
      .skulenmin--article .skulenmin--article--button-bar a.link-button .link-button-text {
        display: block;
        text-transform: uppercase; }
    .skulenmin--article .skulenmin--article--button-bar a.link-button.share-btn span.icon svg {
      height: 32px;
      width: 32px;
      margin: 1px 0 1px 0; }
  .skulenmin--article .carousel-container {
    position: relative; }
    .skulenmin--article .carousel-container .carousel button.slick-arrow {
      width: 44px;
      margin-top: -10px; }
      .skulenmin--article .carousel-container .carousel button.slick-arrow::before {
        font-size: 44px; }
      .skulenmin--article .carousel-container .carousel button.slick-arrow.slick-prev {
        left: 0px;
        z-index: 1; }
      .skulenmin--article .carousel-container .carousel button.slick-arrow.slick-next {
        right: 0px; }
    .skulenmin--article .carousel-container .carousel div.slider-image--container {
      position: relative;
      width: calc(40vw - 40px);
      height: calc((40vw - 40px) / 16 * 9); }
      @media (max-width: 768px) {
        .skulenmin--article .carousel-container .carousel div.slider-image--container {
          width: calc(100vw - 40px);
          height: calc((100vw - 40px) / 16 * 9); } }
      .skulenmin--article .carousel-container .carousel div.slider-image--container img {
        height: 100%;
        width: 100%;
        object-fit: cover; }
      .skulenmin--article .carousel-container .carousel div.slider-image--container img:hover {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        cursor: pointer;
        opacity: 0.5;
        -webkit-transition: 0.3s ease;
        transition: 0.3s ease;
        filter: alpha(opacity=50); }
      .skulenmin--article .carousel-container .carousel div.slider-image--container a.icon-container svg,
      .skulenmin--article .carousel-container .carousel div.slider-image--container button svg {
        height: 44px;
        width: 44px;
        position: absolute;
        fill: gray; }
      .skulenmin--article .carousel-container .carousel div.slider-image--container .slider-image--caption {
        position: absolute;
        bottom: 0;
        width: 100%;
        background: rgba(0, 0, 0, 0.5);
        max-height: 40%;
        color: #ffffff;
        padding: 10px; }
  .skulenmin--article .modal--overlay .modal--content {
    z-index: 100000; }
    .skulenmin--article .modal--overlay .modal--content .modal-carousel {
      height: inherit; }
      .skulenmin--article .modal--overlay .modal--content .modal-carousel .modal-carousel--close {
        position: absolute;
        top: 5px;
        right: 5px;
        height: 32px;
        width: 32px;
        background: #addfd9;
        z-index: 1000;
        border: 0; }
        .skulenmin--article .modal--overlay .modal--content .modal-carousel .modal-carousel--close svg {
          fill: #000000; }

.loading-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1; }
  .loading-overlay .loading-indicator {
    height: 60px;
    width: 60px;
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    .loading-overlay .loading-indicator .loading-indicator-fading-circle {
      height: 100%;
      width: 100%;
      position: absolute; }
    .loading-overlay .loading-indicator .loading-indicator-fading-circle .loading-indicator-circle:before {
      content: "";
      display: block;
      margin: 0 auto;
      width: 15%;
      height: 15%;
      background-color: #333;
      border-radius: 50%;
      -webkit-animation: loading-indicator-circleFadeDelay 1.2s infinite ease-in-out both;
      animation: loading-indicator-circleFadeDelay 1.2s infinite ease-in-out both; }
    .loading-overlay .loading-indicator .loading-indicator-fading-circle .loading-indicator-circle2 {
      -webkit-transform: rotate(30deg);
      transform: rotate(30deg); }
    .loading-overlay .loading-indicator .loading-indicator-fading-circle .loading-indicator-circle3 {
      -webkit-transform: rotate(60deg);
      transform: rotate(60deg); }
    .loading-overlay .loading-indicator .loading-indicator-fading-circle .loading-indicator-circle4 {
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg); }
    .loading-overlay .loading-indicator .loading-indicator-fading-circle .loading-indicator-circle5 {
      -webkit-transform: rotate(120deg);
      transform: rotate(120deg); }
    .loading-overlay .loading-indicator .loading-indicator-fading-circle .loading-indicator-circle6 {
      -webkit-transform: rotate(150deg);
      transform: rotate(150deg); }
    .loading-overlay .loading-indicator .loading-indicator-fading-circle .loading-indicator-circle7 {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg); }
    .loading-overlay .loading-indicator .loading-indicator-fading-circle .loading-indicator-circle8 {
      -webkit-transform: rotate(210deg);
      transform: rotate(210deg); }
    .loading-overlay .loading-indicator .loading-indicator-fading-circle .loading-indicator-circle9 {
      -webkit-transform: rotate(240deg);
      transform: rotate(240deg); }
    .loading-overlay .loading-indicator .loading-indicator-fading-circle .loading-indicator-circle10 {
      -webkit-transform: rotate(270deg);
      transform: rotate(270deg); }
    .loading-overlay .loading-indicator .loading-indicator-fading-circle .loading-indicator-circle11 {
      -webkit-transform: rotate(300deg);
      transform: rotate(300deg); }
    .loading-overlay .loading-indicator .loading-indicator-fading-circle .loading-indicator-circle12 {
      -webkit-transform: rotate(330deg);
      transform: rotate(330deg); }
    .loading-overlay .loading-indicator .loading-indicator-fading-circle .loading-indicator-circle2:before {
      -webkit-animation-delay: -1.1s;
      animation-delay: -1.1s; }
    .loading-overlay .loading-indicator .loading-indicator-fading-circle .loading-indicator-circle3:before {
      -webkit-animation-delay: -1s;
      animation-delay: -1s; }
    .loading-overlay .loading-indicator .loading-indicator-fading-circle .loading-indicator-circle4:before {
      -webkit-animation-delay: -0.9s;
      animation-delay: -0.9s; }
    .loading-overlay .loading-indicator .loading-indicator-fading-circle .loading-indicator-circle5:before {
      -webkit-animation-delay: -0.8s;
      animation-delay: -0.8s; }
    .loading-overlay .loading-indicator .loading-indicator-fading-circle .loading-indicator-circle6:before {
      -webkit-animation-delay: -0.7s;
      animation-delay: -0.7s; }
    .loading-overlay .loading-indicator .loading-indicator-fading-circle .loading-indicator-circle7:before {
      -webkit-animation-delay: -0.6s;
      animation-delay: -0.6s; }
    .loading-overlay .loading-indicator .loading-indicator-fading-circle .loading-indicator-circle8:before {
      -webkit-animation-delay: -0.5s;
      animation-delay: -0.5s; }
    .loading-overlay .loading-indicator .loading-indicator-fading-circle .loading-indicator-circle9:before {
      -webkit-animation-delay: -0.4s;
      animation-delay: -0.4s; }
    .loading-overlay .loading-indicator .loading-indicator-fading-circle .loading-indicator-circle10:before {
      -webkit-animation-delay: -0.3s;
      animation-delay: -0.3s; }
    .loading-overlay .loading-indicator .loading-indicator-fading-circle .loading-indicator-circle11:before {
      -webkit-animation-delay: -0.2s;
      animation-delay: -0.2s; }
    .loading-overlay .loading-indicator .loading-indicator-fading-circle .loading-indicator-circle12:before {
      -webkit-animation-delay: -0.1s;
      animation-delay: -0.1s; }

.article-detail-view--container .article-window {
  position: absolute;
  top: 0;
  right: 0;
  background: #cceceb url(/static/media/sfj_teppe.a02c2eb1.jpg) no-repeat;
  background-size: cover;
  background-color: #89d0c8;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 45vw;
  overflow: auto;
  margin-bottom: 10px;
  padding: 10px; }
  @media (max-width: 768px) {
    .article-detail-view--container .article-window {
      box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.2);
      top: 60vh;
      left: 10px;
      right: 10px;
      width: auto;
      margin-bottom: 0;
      min-height: 60vh; } }
  .article-detail-view--container .article-window .article-detail-view--close-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    height: 32px;
    width: 32px;
    fill: #000000;
    padding: 0;
    background: #addfd9; }
    .article-detail-view--container .article-window .article-detail-view--close-btn .icon svg {
      height: 22px;
      width: 22px; }
  .article-detail-view--container .article-window .article-detail-view--children .article-comments--form button {
    color: #000000; }
  .article-detail-view--container .article-window .article-detail-view--children .article-comments--window {
    position: inherit;
    margin: 20px 0;
    background: #addfd9; }
    .article-detail-view--container .article-window .article-detail-view--children .article-comments--window .article-comments--form {
      position: inherit; }
    .article-detail-view--container .article-window .article-detail-view--children .article-comments--window .comments--comment {
      margin: 5px;
      background: rgba(255, 255, 255, 0.5); }

.comments--container .comments--content {
  position: absolute;
  top: 0;
  right: 0;
  background: #cceceb url(/static/media/sfj_teppe.a02c2eb1.jpg) no-repeat;
  background-size: cover;
  background-color: #89d0c8;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 45vw;
  overflow: auto;
  margin-bottom: 10px;
  padding-top: 10px; }
  @media (max-width: 768px) {
    .comments--container .comments--content {
      box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.2);
      top: 60vh;
      left: 10px;
      right: 10px;
      width: auto;
      margin-bottom: 0;
      min-height: 60vh; } }
  .comments--container .comments--content .comments--window {
    padding: 0 10px; }
    .comments--container .comments--content .comments--window :not(:nth-last-child(-n + 2)) {
      margin-bottom: 10px; }
    .comments--container .comments--content .comments--window .comments--comment {
      background: #ffffff;
      padding: 10px; }

.comments--container .btn-link {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis; }

.nav {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background: #89d0c8;
  color: #000000;
  text-align: right;
  padding: 5px 20px;
  height: 60px;
  z-index: 1000; }
  @media (max-width: 768px) {
    .nav .nav--menu.nav--menu-active {
      background: #65c1b7; } }
  .nav .nav--menu {
    position: absolute;
    top: 0;
    right: 60px;
    background: #89d0c8; }
    .nav .nav--menu .cms--nav a:link,
    .nav .nav--menu .cms--nav a:visited {
      font-family: verdana, sans-serif;
      font-weight: bold;
      font-size: 14px;
      color: #000000;
      position: relative;
      display: inline-block;
      margin: auto 0;
      padding: 20px 10px;
      text-decoration: none;
      /*
        // -------------------
        // adds separators | between navbar links
        // -------------------

        // &:not(:last-child):before {
        //   @include absolute(20px, -1px, 20px);
        //   content: " ";
        //   display: block;
        //   width: 2px;
        //   background: $white;
        // }
      */ }
      .nav .nav--menu .cms--nav a:link:after,
      .nav .nav--menu .cms--nav a:visited:after {
        position: absolute;
        right: 0;
        bottom: 17px;
        left: 0;
        height: 2px;
        width: 0;
        display: block;
        content: " ";
        margin: auto;
        background: #000000;
        -webkit-transition: all ease 200ms;
        transition: all ease 200ms; }
      .nav .nav--menu .cms--nav a:link:hover:after,
      .nav .nav--menu .cms--nav a:visited:hover:after {
        width: 70%;
        opacity: 1; }
    .nav .nav--menu .nav--hamburger {
      display: none; }
  @media (max-width: 768px) {
    .nav .nav--menu {
      position: absolute;
      right: 0;
      height: 60px;
      width: 60px;
      padding-right: 17px;
      padding-left: 17px;
      z-index: 2;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
      text-align: right; }
      .nav .nav--menu .nav--hamburger {
        height: 35px;
        width: 35px;
        display: block;
        margin: 12px -3px -30px;
        opacity: 0;
        cursor: pointer;
        z-index: 2; }
        .nav .nav--menu .nav--hamburger ~ span {
          height: 3px;
          width: 25px;
          display: block;
          background: #000000;
          margin: 4px 2px;
          -webkit-transition: opacity ease-in-out 100ms, -webkit-transform ease-in-out 200ms;
          transition: opacity ease-in-out 100ms, -webkit-transform ease-in-out 200ms;
          transition: transform ease-in-out 200ms, opacity ease-in-out 100ms;
          transition: transform ease-in-out 200ms, opacity ease-in-out 100ms, -webkit-transform ease-in-out 200ms;
          opacity: 1;
          pointer-events: none; }
        .nav .nav--menu .nav--hamburger:checked ~ span:nth-child(2) {
          -webkit-transform: translateY(7px) translateX(0px) rotate(-45deg);
                  transform: translateY(7px) translateX(0px) rotate(-45deg); }
        .nav .nav--menu .nav--hamburger:checked ~ span:nth-child(4) {
          -webkit-transform: translateY(-7px) translateX(0px) rotate(45deg);
                  transform: translateY(-7px) translateX(0px) rotate(45deg); }
        .nav .nav--menu .nav--hamburger:checked ~ span:nth-child(3) {
          opacity: 0; }
      .nav .nav--menu .cms--nav {
        position: absolute;
        top: 59px;
        right: 0;
        height: 0;
        width: calc(100vw - 40px);
        max-width: 320px;
        background: #65c1b7;
        display: block;
        overflow: hidden;
        text-align: left;
        opacity: 0;
        -webkit-transition: opacity 200ms;
        transition: opacity 200ms;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); }
        .nav .nav--menu .cms--nav a:link {
          display: block;
          padding: 20px;
          right: 0; }
        .nav .nav--menu .cms--nav a:hover {
          background: #89d0c8; }
        .nav .nav--menu .cms--nav a:link:after {
          display: none; }
      .nav .nav--menu .nav--hamburger:checked ~ .cms--nav {
        height: auto;
        opacity: 1; } }
  .nav .buttons {
    position: absolute;
    top: 0;
    right: 0; }
    .nav .buttons .btn {
      border: none; }
    .nav .buttons > * {
      display: block; }
    @media (max-width: 768px) {
      .nav .buttons {
        position: absolute;
        top: 0;
        right: 120px; } }
  .nav .nav-bar--search-button {
    position: absolute;
    top: 0;
    right: 0;
    height: 60px;
    width: 60px;
    background: #89d0c8;
    border: none; }
    .nav .nav-bar--search-button:focus {
      outline: 0; }
    @media (max-width: 768px) {
      .nav .nav-bar--search-button {
        right: 60px; } }
    .nav .nav-bar--search-button > span > svg {
      height: 60px;
      width: 60px;
      padding: 15px;
      max-height: 60px;
      max-width: 60px;
      fill: #000000; }
  .nav .nav-bar-active,
  .nav .nav-bar--search-active {
    background: #65c1b7;
    -webkit-transition: background 0.2s;
    transition: background 0.2s; }
  .nav .nav-bar--search {
    opacity: 1;
    visibility: visible;
    -webkit-transition: opacity 0.2, visibility 0.2s;
    transition: opacity 0.2, visibility 0.2s; }
    .nav .nav-bar--search .search--container {
      position: fixed;
      top: 60px;
      right: 0px;
      width: 480px; }
      .nav .nav-bar--search .search--container .search--inputWrap {
        width: 100%;
        display: table;
        margin: 0, auto;
        background: #65c1b7;
        box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); }
        .nav .nav-bar--search .search--container .search--inputWrap input {
          width: calc(100% - 20px);
          padding: 9px 15px;
          margin: 10px;
          border: none; }
        .nav .nav-bar--search .search--container .search--inputWrap ::-webkit-input-placeholder {
          font-style: italic; }
        .nav .nav-bar--search .search--container .search--inputWrap :-moz-placeholder {
          font-style: italic; }
        .nav .nav-bar--search .search--container .search--inputWrap ::-moz-placeholder {
          font-style: italic; }
        .nav .nav-bar--search .search--container .search--inputWrap :-ms-input-placeholder {
          font-style: italic; }
        .nav .nav-bar--search .search--container .search--inputWrap .btn.btn-link {
          height: 32px;
          width: 32px;
          background: transparent;
          float: right;
          margin-top: -46px;
          margin-right: 20px; }
          .nav .nav-bar--search .search--container .search--inputWrap .btn.btn-link .icon svg {
            height: 28px;
            width: 28px;
            fill: #cccccc; }
      .nav .nav-bar--search .search--container .form-control {
        border-radius: 0; }
      @media (max-width: 1000px) {
        .nav .nav-bar--search .search--container {
          width: 480px; } }
      @media (max-width: 768px) {
        .nav .nav-bar--search .search--container {
          left: 0;
          width: 100%; }
          .nav .nav-bar--search .search--container .form-control {
            border-radius: 0;
            border-style: none none solid none; } }
      .nav .nav-bar--search .search--container .search--loadingElem {
        position: absolute;
        top: 50%;
        right: 70px;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%); }
      .nav .nav-bar--search .search--container .search--searchSources:not(:empty) {
        max-height: calc(100vh - 100px); }
  .nav .nav-bar--search-hidden {
    visibility: hidden;
    opacity: 0; }
  .nav a.nav--logo {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    height: 60px;
    display: block;
    fill: #000000; }
    .nav a.nav--logo img {
      height: 100%; }

.core-layout--root {
  position: absolute;
  top: 60px;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  pointer-events: none;
  margin: 10px; }
  .core-layout--root > * {
    pointer-events: all; }
  @media (max-width: 768px) {
    .core-layout--root {
      margin: 0; } }

.page-layout--root {
  position: relative;
  top: 60px;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  pointer-events: none;
  margin: 10px; }
  @media (max-width: 768px) {
    .page-layout--root {
      margin: 0; } }
  .page-layout--root .page-layout--components {
    max-width: 1260px;
    margin-left: 15%;
    margin-right: 15%;
    padding-top: 10px; }
  .page-layout--root > * {
    pointer-events: all; }

.page-view--container .page-view--content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 20px 30px 0; }
  .page-view--container .page-view--content .articles--container {
    background: none; }
  .page-view--container .page-view--content .content {
    position: relative;
    z-index: 100;
    margin-bottom: 30px; }
    .page-view--container .page-view--content .content img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      overflow: hidden;
      margin: 30px 0; }
  @media (max-width: 768px) {
    .page-view--container .page-view--content {
      padding-left: 20px;
      padding-right: 20px;
      height: auto;
      min-height: 40vh; } }

body {
  color: #000;
  font-family: "Lato", sans-serif;
  font-size: 14px; }
  body .react-openlayers--map {
    position: fixed;
    height: 100vh;
    width: 100vw; }
  body .thumbnail,
  body .btn {
    border-radius: 0; }
  body .btn {
    font-size: 13px; }
  body .btn-primary {
    background: #40b9b4;
    border-color: #40b9b4;
    font-weight: bold; }
    body .btn-primary:hover {
      background: #39a6a2;
      border-color: #39a6a2; }
  body .btn-link {
    color: #336187; }
    body .btn-link:hover {
      color: #2c5474; }
    body .btn-link[disabled] {
      color: #777;
      cursor: default; }
  body input,
  body textarea,
  body .form-control {
    border-color: form-border-color; }
  body .react-openlayers--show-location.ol-control {
    position: absolute;
    top: 66px;
    right: auto;
    left: 10px; }
    body .react-openlayers--show-location.ol-control:hover, body .react-openlayers--show-location.ol-control:focus {
      background: none; }
    body .react-openlayers--show-location.ol-control button {
      background: #40b9b4; }
      body .react-openlayers--show-location.ol-control button svg {
        fill: #000000; }
      body .react-openlayers--show-location.ol-control button:hover, body .react-openlayers--show-location.ol-control button:focus {
        background: #39a6a2; }
  body div.ol-control {
    background-color: transparent; }
  body div.ol-control.ol-zoom {
    position: absolute;
    top: 100px;
    left: 10px; }
    body div.ol-control.ol-zoom:hover, body div.ol-control.ol-zoom:focus {
      background: none; }
  @media (max-width: 768px) {
    body .ol-zoom {
      display: none; } }
  body .ol-zoom .ol-zoom-in,
  body .ol-zoom .ol-zoom-out,
  body .ol-unselectable .ol-zoom-in,
  body .ol-unselectable .ol-zoom-out,
  body .ol-control .ol-zoom-in,
  body .ol-control .ol-zoom-out {
    background-color: #40b9b4;
    color: #000000; }
  body .ol-control:focus,
  body .ol-control:hover {
    background-color: #39a6a2;
    outline: 0; }
    body .ol-control:focus .ol-zoom-in:hover,
    body .ol-control:focus .ol-zoom-out:hover,
    body .ol-control:focus .ol-zoom-in:focus,
    body .ol-control:focus .ol-zoom-out:focus,
    body .ol-control:hover .ol-zoom-in:hover,
    body .ol-control:hover .ol-zoom-out:hover,
    body .ol-control:hover .ol-zoom-in:focus,
    body .ol-control:hover .ol-zoom-out:focus {
      background-color: #39a6a2;
      outline: 0; }
  body .ol-scale-line {
    background: #40b9b4; }
    body .ol-scale-line .ol-scale-line-inner {
      color: #000000;
      border-color: #000000; }
  @media (max-width: 768px) {
    body .ol-scale-line {
      display: none; } }
  body .btn-primary.active.focus,
  body .btn-primary.active:focus,
  body .btn-primary.active:hover,
  body .btn-primary:active.focus,
  body .btn-primary:active:focus,
  body .btn-primary:active:hover,
  body .open > .dropdown-toggle.btn-primary.focus,
  body .open > .dropdown-toggle.btn-primary:focus,
  body .open > .dropdown-toggle.btn-primary:hover,
  body .btn-primary.active,
  body .btn-primary:active,
  body .open > .dropdown-toggle.btn-primary {
    background-color: #40b9b4;
    border-color: #40b9b4;
    color: #000000; }

body input,
body textarea,
body .form-control {
  border-color: #ccc; }

a,
a:visited {
  color: #1173af;
  text-decoration: underline; }

a:hover {
  color: #1692dd; }

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.rotating {
  -webkit-animation: rotating 1.5s linear infinite;
          animation: rotating 1.5s linear infinite; }

