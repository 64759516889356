@import "../style/theme";

body.modal-open {
  // div {
  //   // overflow: hidden;
  // }
  overflow: hidden;
  // position: fixed;
}

.modal--overlay {
  position: fixed;
  background: rgba(255, 255, 255, 0.6);
  display: block;
  height: 100vh;
  width: 100vw;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  overflow: auto;

  .modal--content {
    position: relative;
    top: 50%;
    left: 50%;
    width: 90%;
    max-width: 690px;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    z-index: 1000;
    height: 80vh;

    @media (max-width: 768px) {
      top: 75px;
      left: 50%;
      transform: translateX(-50%);
      width: 90%;
      z-index: 1000;
      margin-bottom: 30px;
    }
  }
}
