@import "../style/theme.scss";

.comments--container {
  .comments--content {
    @include contentBox();
    padding-top: 10px;
    .comments--window {
      padding: 0 10px;
      :not(:nth-last-child(-n + 2)) {
        margin-bottom: 10px;
      }
      .comments--comment {
        background: $white;
        padding: 10px;
      }
    }
  }

  .btn-link {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
