@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600");
@import url("//maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css");
@import "./style/theme.scss";


body {
  color: #000;
  font-family: $font-family-main;
  font-size: 14px;

  .react-openlayers--map {
    position: fixed;
    height: 100vh;
    width: 100vw;
  }

  .thumbnail,
  .btn {
    border-radius: 0;
  }

  .btn {
    font-size: 13px;
  }

  .btn-primary {
    background: $button-background;
    border-color: $button-border-color;
    font-weight: bold;

    &:hover {
      background: darken($button-background, 5%);
      border-color: darken($button-background, 5%);
    }
  }

  .btn-link {
    color: $button-link-color;
    &:hover {
      color: darken($button-link-color, 5%);
    }

    &[disabled] {
      color: #777;
      cursor: default;
    }
  }

  input,
  textarea,
  .form-control {
    border-color: form-border-color;
  }

  .react-openlayers--show-location.ol-control {
    @include absolute(66px, auto, $left: 10px);
    &:hover,
    &:focus {
      background: none;
    }

    button {
      background: $button-background;
      svg {
        fill: $color-text-dark;
      }
      &:hover,
      &:focus {
        background: darken($button-background, 5%);
      }
    }
  }

  div.ol-control {
    background-color: transparent;
  }

  div.ol-control.ol-zoom {
    @include absolute(100px, $left: 10px);
    &:hover,
    &:focus {
      background: none;
    }
  }

  @include media("<=tablet") {
    .ol-zoom {
      display: none;
    }
  }

  .ol-zoom,
  .ol-unselectable,
  .ol-control {
    .ol-zoom-in,
    .ol-zoom-out {
      background-color: $button-background;
      color: $color-text-dark;
    }
  }

  .ol-control:focus,
  .ol-control:hover {
    background-color: darken($button-background, 5%);
    outline: 0;
    .ol-zoom-in:hover,
    .ol-zoom-out:hover,
    .ol-zoom-in:focus,
    .ol-zoom-out:focus {
      background-color: darken($button-background, 5%);
      outline: 0;
    }
  }

  .ol-scale-line {
    background: $button-background;
    .ol-scale-line-inner {
      color: $color-text-dark;
      border-color: $color-text-dark
    }
  }

  @include media("<=tablet") {
    .ol-scale-line {
      display: none;
    }
  }

  .btn-primary.active.focus,
  .btn-primary.active:focus,
  .btn-primary.active:hover,
  .btn-primary:active.focus,
  .btn-primary:active:focus,
  .btn-primary:active:hover,
  .open > .dropdown-toggle.btn-primary.focus,
  .open > .dropdown-toggle.btn-primary:focus,
  .open > .dropdown-toggle.btn-primary:hover,
  .btn-primary.active,
  .btn-primary:active,
  .open > .dropdown-toggle.btn-primary {
    background-color: $button-background;
    border-color: $button-border-color;
    color: $button-color;
  }
}

body input,
body textarea,
body .form-control {
  border-color: $form-border-color;
}

a,
a:visited {
  color: $theme-color;
  text-decoration: underline;
}
a:hover {
  color: lighten($theme-color, 10%);
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.rotating {
  animation: rotating 1.5s linear infinite;
}
