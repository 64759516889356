@import "../style/theme.scss";
@import "~slick-carousel/slick/slick-theme.css";
@import "~slick-carousel/slick/slick.css";

.carousel {
  button.slick-arrow {
    width: 44px;
    top: 50%;
    margin-top: -10px;
    z-index: 1;

    &::before {
      font-size: 44px;
    }

    &.slick-prev {
      left: 0px;
    }

    &.slick-next {
      right: 0px;
    }
  }

  div.slider-image--container {
    position: relative;
    height: 900px;
    img {
      width: 100%;
      height: auto;
      z-index: 1000;
    }

    .slider-image--caption {
      width: 100%;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      bottom: 0;
      max-height: 40%;
      color: $white;
      padding: 10px;
    }
  }
}

.gallery--carousel {
  button.slick-arrow {
    width: 44px;
    top: 50%;
    margin-top: -10px;
    z-index: 1;

    &::before {
      font-size: 44px;
    }

    &.slick-prev {
      left: -150px;
      
      @media(max-width: 1024px){
        left:0;
      }

      &:before {
        color: rgba(0, 0, 0, 0.5);
        &:hover {
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }

    &.slick-next {
      right: -150px;
      
      @media(max-width: 1024px){
        right:0;
      }

      &:before {
        color: rgba(0, 0, 0, 0.5);
        &:hover {
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }
  }

  div.slick-slide {
    float: none;
    display: inline-block;
    vertical-align: middle;

    div.slider-image--container {
      position: relative;
      max-height: 800px;

      img {
        width: 100%;
        max-height: 100%;
        height: auto;
        z-index: 1000;
        object-fit: cover;
        opacity: 100;
      }

      .slider-image--caption {
        width: 100%;
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        bottom: 0;
        max-height: 40%;
        color: $white;
        padding: 10px;
        z-index: 1001;
      }
    }
  }
}
