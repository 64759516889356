@import "../../style/theme.scss";

.skulenmin--article {
  background: $white;
  padding: 10px;
  .skulenmin--article-content-header {
    padding-top: 10px;
  }

  .skulenmin--article-content {
    margin: 20px 0;
    overflow-x: auto;
    
    .thumb {
      margin-bottom: 15px;
    }

    br {
      display: none;
    }
    h2 {
      font-size: 20px;
    }

    h3 {
      font-size: 16px;
    }

    table.wikitable {
      border: 1px solid #333;
      width: 100%;
      text-align: center;
      border-collapse: collapse;
    }
    table.wikitable td,
    table.wikitable th {
      border: 1px solid #333;
      padding: 3px;
    }
    table.wikitable tbody td {
      font-size: 12px;
    }
    table.wikitable tr:nth-child(even) {
      background: #d0e4f5;
    }
    table.wikitable {
      border-bottom: 1px solid #333333;
    }
    table.wikitable th {
      font-size: 13px;
      font-weight: bold;
      color: #333333;
      text-align: center;
      border-left: 1px solid #333333;
    }
    table.wikitable th:first-child {
      border-left: none;
    }

    table.wikitable td {
      font-size: 12px;
    }
    @include media(">=tablet", "<=desktop") {
      table.wikitable {
        overflow-x: auto;
        display: block;
      }
    }
    @include media("<=580px") {
      table.wikitable {
        overflow-x: auto;
        display: block;
      }
    }
    
    .additionalColumn {
      .additionalColumnLabel {
        font-style: italic;
        font-weight: 400;
      }
    }
  }
  .skulenmin--article-source {
    span {
      font-style: italic;
      margin-right: 6px;
    }
  }

  .skulenmin--article--button-bar {
    display: flex;
    margin-top: 30px;

    a.link-button {
      flex: 1;
      padding: 10px 8px;
      background: #cceceb;
      text-decoration: none;
      margin: 5px 2px 5px 2px;
      text-align: center;
      color: $color-text-dark;
      position: relative;

      span.icon {
        display: block;

        svg {
          top: auto;
          left: auto;
          transform: none;
          position: relative;
          fill: $color-text-dark;
          width: 34px;
          height: 34px;
        }
      }

      .link-button-text{
        display: block;
      text-transform: uppercase;
      }
    }
  
    a.link-button.share-btn {
      span.icon {
        svg {
          height: 32px;
          width: 32px;
          margin: 1px 0 1px 0;
        }
    }
  }
}
  .carousel-container {
    position: relative;

    .carousel {
      button.slick-arrow {
        width: 44px;
        margin-top: -10px;

        &::before {
          font-size: 44px;
          //   color: $btn-color;
        }

        &.slick-prev {
          left: 0px;
          z-index: 1;
        }

        &.slick-next {
          right: 0px;
        }
      }

      div.slider-image--container {
        position: relative;
        width: calc(40vw - 40px);
        height: calc((40vw - 40px) / 16 * 9);

        @media (max-width: 768px) {
          width: calc(100vw - 40px);
          height: calc((100vw - 40px) / 16 * 9);
        }

        img {
          @include square(100%);
          object-fit: cover;
        }

        img:hover {
          @include absolute-pos-full();
          @include square(100%);
          cursor: pointer;
          opacity: 0.5;
          transition: 0.3s ease;
          filter: alpha(opacity=50);
        }

        a.icon-container,
        button {
          svg {
            @include square(44px);
            position: absolute;
            fill: gray;
          }
        }

        .slider-image--caption {
          @include absolute($bottom: 0);
          width: 100%;
          background: rgba(0, 0, 0, 0.5);
          max-height: 40%;
          color: $white;
          padding: 10px;
        }
      }
    }
  }

  .modal--overlay {
    .modal--content {
      z-index: 100000;

      .modal-carousel {
        height: inherit;
        .modal-carousel--close {
          @include absolute(5px, 5px);
          @include square(32px);
          background: lighten($primary-color, 10%);
          z-index: 1000;
          border: 0;
          svg {
            fill: $black;
          }
        }
      }
    }
    }
}
