@import "../style/theme.scss";

.loading-overlay {
  @include absolute-pos-full();
  background: rgba(0, 0, 0, 0.2);
  z-index: 1;

  .loading-indicator {
    @include square(60px);
    @include fixed(50%, $left: 50%);
    transform: translate(-50%, -50%);

    .loading-indicator-fading-circle {
      @include square(100%);
      position: absolute;
    }

    .loading-indicator-fading-circle .loading-indicator-circle:before {
      content: "";
      display: block;
      margin: 0 auto;
      width: 15%;
      height: 15%;
      background-color: #333;
      border-radius: 50%;
      -webkit-animation: loading-indicator-circleFadeDelay 1.2s infinite
        ease-in-out both;
      animation: loading-indicator-circleFadeDelay 1.2s infinite ease-in-out
        both;
    }

    .loading-indicator-fading-circle .loading-indicator-circle2 {
      -webkit-transform: rotate(30deg);
      -ms-transform: rotate(30deg);
      transform: rotate(30deg);
    }

    .loading-indicator-fading-circle .loading-indicator-circle3 {
      -webkit-transform: rotate(60deg);
      -ms-transform: rotate(60deg);
      transform: rotate(60deg);
    }

    .loading-indicator-fading-circle .loading-indicator-circle4 {
      -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg);
    }

    .loading-indicator-fading-circle .loading-indicator-circle5 {
      -webkit-transform: rotate(120deg);
      -ms-transform: rotate(120deg);
      transform: rotate(120deg);
    }

    .loading-indicator-fading-circle .loading-indicator-circle6 {
      -webkit-transform: rotate(150deg);
      -ms-transform: rotate(150deg);
      transform: rotate(150deg);
    }

    .loading-indicator-fading-circle .loading-indicator-circle7 {
      -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
    }

    .loading-indicator-fading-circle .loading-indicator-circle8 {
      -webkit-transform: rotate(210deg);
      -ms-transform: rotate(210deg);
      transform: rotate(210deg);
    }

    .loading-indicator-fading-circle .loading-indicator-circle9 {
      -webkit-transform: rotate(240deg);
      -ms-transform: rotate(240deg);
      transform: rotate(240deg);
    }

    .loading-indicator-fading-circle .loading-indicator-circle10 {
      -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
      transform: rotate(270deg);
    }

    .loading-indicator-fading-circle .loading-indicator-circle11 {
      -webkit-transform: rotate(300deg);
      -ms-transform: rotate(300deg);
      transform: rotate(300deg);
    }

    .loading-indicator-fading-circle .loading-indicator-circle12 {
      -webkit-transform: rotate(330deg);
      -ms-transform: rotate(330deg);
      transform: rotate(330deg);
    }

    .loading-indicator-fading-circle .loading-indicator-circle2:before {
      -webkit-animation-delay: -1.1s;
      animation-delay: -1.1s;
    }

    .loading-indicator-fading-circle .loading-indicator-circle3:before {
      -webkit-animation-delay: -1s;
      animation-delay: -1s;
    }

    .loading-indicator-fading-circle .loading-indicator-circle4:before {
      -webkit-animation-delay: -0.9s;
      animation-delay: -0.9s;
    }

    .loading-indicator-fading-circle .loading-indicator-circle5:before {
      -webkit-animation-delay: -0.8s;
      animation-delay: -0.8s;
    }

    .loading-indicator-fading-circle .loading-indicator-circle6:before {
      -webkit-animation-delay: -0.7s;
      animation-delay: -0.7s;
    }

    .loading-indicator-fading-circle .loading-indicator-circle7:before {
      -webkit-animation-delay: -0.6s;
      animation-delay: -0.6s;
    }

    .loading-indicator-fading-circle .loading-indicator-circle8:before {
      -webkit-animation-delay: -0.5s;
      animation-delay: -0.5s;
    }

    .loading-indicator-fading-circle .loading-indicator-circle9:before {
      -webkit-animation-delay: -0.4s;
      animation-delay: -0.4s;
    }

    .loading-indicator-fading-circle .loading-indicator-circle10:before {
      -webkit-animation-delay: -0.3s;
      animation-delay: -0.3s;
    }

    .loading-indicator-fading-circle .loading-indicator-circle11:before {
      -webkit-animation-delay: -0.2s;
      animation-delay: -0.2s;
    }

    .loading-indicator-fading-circle .loading-indicator-circle12:before {
      -webkit-animation-delay: -0.1s;
      animation-delay: -0.1s;
    }
  }
}
