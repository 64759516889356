@import "../../style/theme.scss";

.articleList--thumbnail img {
  display: none;
}
.articleList--thumbnail.articleList--withThumbnail {
  min-height: 150px;
  .caption {
    background: rgba(0, 58, 105, 0.75);
    max-height: 100%;
    max-height: calc(100% - 10px);
    overflow: auto;
    .pull-right.btn.btn-link {
      @include absolute-pos-full();
      overflow: hidden;
      width: 100%;
      display: block;
      text-indent: -9999em;
      text-transform: uppercase;
    }
  }
  img {
    display: inherit;
  }
}

.articleList--entry {
  border: 1px solid #99dad7;
  background: #fff;
  border-radius: 4px;
  border-top-width: 6px !important;
  cursor: pointer;

  .read-more-btn {
    float: right;
  }
}

.article:hover {
  border-color: #00a29b;
}
