@import "../style/mixins.scss";

.core-layout--root {
  @include absolute(60px, 0, 0, 0);
  box-sizing: border-box;
  pointer-events: none;
  margin: 10px;

  & > * {
    pointer-events: all;
  }
  @media (max-width: 768px) {
    margin: 0;
  }
}
