@import "../style/theme.scss";

.page-view--container {
  .page-view--content {
    display: flex;
    flex-direction: column;
    padding: 20px 30px 0;

    .articles--container {
      background: none;
    }

    .content {
      position: relative;
      z-index: 100;
      margin-bottom: 30px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
        margin: 30px 0;
      }
    }

    @include media("<=tablet") {
      padding-left: 20px;
      padding-right: 20px;
      height: auto;
      min-height: 40vh;
    }
  }
}
