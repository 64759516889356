@import "../style/theme.scss";

.article-detail-view--container {
  .article-window {
    @include contentBox();
    padding: 10px;

  .article-detail-view--close-btn {
    @include absolute(15px, 15px);
    @include square(32px);
    fill: $color-text-dark;
    padding: 0;
    background: lighten($primary-color, 10%);

    .icon {
      svg {
        @include square(22px);
      }
    }
  }

  .article-detail-view--children {

    .article-comments--form {
      button {
        color:  $color-text-dark;
      }
    }

    .article-comments--window {
      position: inherit;
      margin: 20px 0;
      background: lighten($primary-color, 10%);
      
        .article-comments--form {
          position: inherit;
        }

        .comments--comment {
          margin: 5px;
          background: rgba(255,255,255,0.5);
        }    
      }
    }
  }
}