@import "../style/theme.scss";

.article-view--container {
  background: transparent;

  .article-view--content {
    @include contentBox();
    padding: 10px;

    .articles--container {
      background: transparent;
      

      .articleList--thumbnail img {
        display: none;
      }
      .articleList--thumbnail.articleList--withThumbnail {
        min-height: 150px;
        .caption {
          background: rgba(0, 58, 105, 0.75);
          max-height: 100%;
          max-height: calc(100% - 10px);
          overflow: auto;
          .pull-right.btn.btn-link {
            @include absolute-pos-full();
            overflow: hidden;
            width: 100%;
            display: block;
            text-indent: -9999em;
            text-transform: uppercase;
          }
        }
        img {
          display: inherit;
        }
      }

      .articleList--entry {
        border: 1px solid lighten($primary-color, 10%);
        background: #fff;
        border-radius: 4px;
        border-top-width: 6px !important;
      }

      .article:hover {
        border-color: #00a29b;
      }

      .articles--selector{
        #articleSourceSelector {
          background: $button-background;
          color: $color-text-dark;
          border-color: $button-border-color;
        }
      }
    }
  }
}
