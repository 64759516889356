@import "../style/theme.scss";

.nav {
  @include fixed(0, 0, null, 0);
  background: $color-nav;
  color: $color-text-dark;
  text-align: right;
  padding: 5px 20px;
  height: 60px;
  z-index: 1000;

  .nav--menu.nav--menu-active {
    @include media("<=tablet") {
      background: darken($color-nav, 10);
    }
  }
  .nav--menu {
    @include absolute(0, 60px);
    background: $color-nav;
    .cms--nav {
      a:link,
      a:visited {
        @include main-font-style(bold, 14px, $color-text-dark);
        @include relative();
        display: inline-block;
        margin: auto 0;
        padding: 20px 10px;
        text-decoration: none;
        &:after {
          @include absolute($left: 0, $bottom: 17px, $right: 0);
          @include size(0, 2px);
          display: block;
          content: " ";
          margin: auto;
          background: $color-text-dark;
          transition: all ease 200ms;
        }
        &:hover:after {
          width: 70%;
          opacity: 1;
        }

        /*
        // -------------------
        // adds separators | between navbar links
        // -------------------

        // &:not(:last-child):before {
        //   @include absolute(20px, -1px, 20px);
        //   content: " ";
        //   display: block;
        //   width: 2px;
        //   background: $white;
        // }
      */
      }
    }
    .nav--hamburger {
      display: none;
    }
  }

  @include media("<=tablet") {
    .nav--menu {
      @include absolute($right: 0);
      @include square(60px);
      padding-right: 17px;
      padding-left: 17px;
      z-index: 2;
      user-select: none;
      text-align: right;
      .nav--hamburger {
        @include size(35px, 35px);
        display: block;
        margin: 12px -3px -30px;
        opacity: 0;
        cursor: pointer;
        z-index: 2;
        & ~ span {
          @include size(25px, 3px);
          display: block;
          background: $black;
          margin: 4px 2px;
          transition: transform ease-in-out 200ms, opacity ease-in-out 100ms;
          opacity: 1;
          pointer-events: none;
        }
        &:checked ~ span:nth-child(2) {
          transform: translateY(7px) translateX(0px) rotate(-45deg);
        }
        &:checked ~ span:nth-child(4) {
          transform: translateY(-7px) translateX(0px) rotate(45deg);
        }
        &:checked ~ span:nth-child(3) {
          opacity: 0;
        }
      }
      .cms--nav {
        @include absolute(59px, 0);
        @include size(calc(100vw - 40px), 0);
        max-width: 320px;
        background: darken($color-nav, 10);
        display: block;
        overflow: hidden;
        text-align: left;
        opacity: 0;
        transition: opacity 200ms;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
        a:link {
          display: block;
          padding: 20px;
          right: 0;
        }
        a:hover {
          background: $color-nav;
        }
        a:link:after {
          display: none;
        }
      }
      .nav--hamburger:checked ~ .cms--nav {
        height: auto;
        opacity: 1;
      }
    }
  }

  .buttons {
    @include absolute(0, 0);
    .btn {
      border: none;
    }

    & > * {
      display: block;
    }

    @include media("<=tablet") {
      @include absolute(0, 120px);
    }
  }

  .nav-bar--search-button {
    @include absolute(0, 0);
    @include square(60px);
    background: $color-nav;
    border: none;

    &:focus {
      outline: 0;
    }

    @include media("<=tablet") {
      right: 60px;
    }

    > span > svg {
      @include square(60px);
      padding: 15px;
      max-height: 60px;
      max-width: 60px;
      fill: $black;
    }
  }
  .nav-bar-active,
  .nav-bar--search-active {
    background: darken($color-nav, 10);
    transition: background 0.2s;
  }

  .nav-bar--search {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.2, visibility 0.2s;

    .search--container {
      @include fixed(60px, 0px);
      width: 480px;

      .search--inputWrap {
        width: 100%;
        display: table;
        margin: 0, auto;
        background: darken($color-nav, 10);
        box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2),
          0 6px 20px 0 rgba(0, 0, 0, 0.19);

        input {
          width: calc(100% - 20px);
          padding: 9px 15px;
          margin: 10px;
          border: none;
        }
        ::-webkit-input-placeholder {
          font-style: italic;
        }
        :-moz-placeholder {
          font-style: italic;
        }
        ::-moz-placeholder {
          font-style: italic;
        }
        :-ms-input-placeholder {
          font-style: italic;
        }

        .btn.btn-link {
          @include square(32px);
          background: transparent;
          float: right;
          margin-top: -46px;
          margin-right: 20px;

          .icon svg {
            @include square(28px);
            fill: $grey;
          }
        }
      }

      .form-control {
        border-radius: 0;
      }

      @media (max-width: 1000px) {
        width: 480px;
      }

      @media (max-width: 768px) {
        left: 0;
        width: 100%;

        .form-control {
          border-radius: 0;
          border-style: none none solid none;
        }
      }

      .search--loadingElem {
        @include absolute(50%, 70px);
        transform: translateY(-50%);
      }

      .search--searchSources:not(:empty) {
        max-height: calc(100vh - 100px);
      }
    }
  }

  .nav-bar--search-hidden {
    visibility: hidden;
    opacity: 0;
  }

  a.nav--logo {
    @include absolute(50%);
    transform: translateY(-50%);
    height: 60px;
    display: block;
    fill: $black;

    img {
      height: 100%;
    }
  }
}
