@import "./colors";
@import "./media-queries.scss";

@mixin main-font-style($weight: normal, $size: 14px, $color: $color-text-dark) {
  font-family: verdana, sans-serif;
  font-weight: $weight;
  font-size: $size;
  color: $color;
}
@mixin size($width: $width, $height: $height) {
  height: $height;
  width: $width;
}
@mixin square($size) {
  @include size($size, $size);
}
@mixin position(
  $position,
  $top: null,
  $right: null,
  $bottom: null,
  $left: null
) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}
@mixin absolute($args...) {
  @include position(absolute, $args...);
}
@mixin relative($args...) {
  @include position(relative, $args...);
}
@mixin fixed($args...) {
  @include position(fixed, $args...);
}
@mixin absolute-pos-full() {
  @include absolute(0, 0, 0, 0);
}
@mixin contentBox($lgWidth: 45vw) {
  @include absolute(0, 0);
  background: #cceceb url("../static/sfj_teppe.jpg") no-repeat;
  background-size: cover;
  background-color: $color-nav;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: $lgWidth;
  overflow: auto;
  margin-bottom: 10px;
  @include media("<=tablet") {
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.2);
    top: 60vh;
    left: 10px;
    right: 10px;
    width: auto;
    margin-bottom: 0;
    min-height: 60vh;
  }
}
