@import "../style/theme.scss";

.page-layout--root {
  @include relative(60px, 0, 0, 0);
  box-sizing: border-box;
  pointer-events: none;
  margin: 10px;

  @include media("<=tablet") {
    margin: 0;
  }

  .page-layout--components {
    max-width: 1260px;
    margin-left: 15%;
    margin-right: 15%;
    padding-top: 10px;
  }

  & > * {
    pointer-events: all;
  }
}
