// ------------------------
// internal Variables
// ------------------------

$white: #ffffff;
$black: #000000;
$grey: #cccccc;
$primary-color: #89d0c8;

// ------------------------
// Global Variables
// ------------------------

$color-text-dark: $black;
$color-nav: $primary-color;

$theme-color: #1173af;

$button-background:#40b9b4;
$button-border-color:#40b9b4;
$button-color: $black;

$form-border-color: #ccc;

$button-link-color:#336187;


